<template>
  <div>
    <div class="vld-parent">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
                 background-color="#000000">
                <div style="padding: 5em; background-color: white; border-radius: 5%;">
                  <div class="d-flex justify-content-center">
                      <div class="loader"></div>
                  </div>
                </div>
        </loading>
    </div>
    <br />
    <div class="modal" tabindex="-1" role="dialog" v-if="isModalResultadoVisible">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Definir restultado final da Enquete</h5>
          </div>
          <div class="modal-body">
            <table class="table" v-if="enquete && enquete.opcoes">
              <tr v-for="op in enquete.opcoes">
                  <td>{{ op.texto }}</td>
                  <!-- <td><input class="form-check-input" type="radio" name="radio_options" :value="op.texto" v-model="resultado"></td> -->
                  <td><input class="form-check-input" type="checkbox" :value="op.id" v-model="resultados"></td>
              </tr>
            </table>
            <div style="border-top: 1px solid orange">
                <span class="check-hint">Deixar todos em branco caso não tenha ocorrido nenhuma das opções</span>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="isModalResultadoVisible=false" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="salvarResultado">Definir Resultado</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <h4>Texto da enquete</h4>
        <vue-editor v-model="enquete.mensagem" :editorToolbar="customToolbar" :init="{height: 500}" disabled></vue-editor>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-md-8" v-if="enquete.tipo_grupo_id">
        <p>Tipo:</p>
        <input type="text"class="form-control" :value="getTipoGrupo(enquete.tipo_grupo_id)" disabled/>
      </div>
      <div class="col-md-8" v-else>
        <h4>Grupos:</h4>
        <ul class="ul-grp">
          <li v-for="grp in grupos">{{grp['nome']}}</li>
        </ul>
      </div>
    </div>
    <br />
    <div class="row mt-1 mb-1" style="margin-top:-10px;">
      <div class="col-md-8 ">
        <div style="border-bottom:2px solid #F9B9A9; border-top:2px solid #F9B9A9;" class="pt-2 pb-3">
          <div v-if="campanha">
            <h4>Campanha: <b>{{ campanha.nome }}</b></h4>
            <h6>
              Status: 
              <b>
                <span style="color:#FC7D59;" v-if="campanha.is_encerrada">ENCERRADA</span>
                <span style="color:#3A9B6C;" v-else>ATIVA</span>
              </b>
            </h6>
          </div>
          <div  class="pt-2" v-else>
            <h4>Campanha: <b>NENHUMA</b></h4>
          </div>
        </div>
      </div>
    </div>
    <template v-if="enquete.dh_resultado">
      <br />
      <div class="row">
        <div class="col-8 encerrada">
            ENQUETE ENCERRADA E RESULTADO CALCULADO EM: {{enquete.dh_resultado }}
        </div>
      </div>
    </template>
    <br />
    <div class="row">
      <div class="col-6">
        <h4>Opções</h4>
        <table>
          <tr>
            <th>Nome</th>
            <th v-if="enquete.campanha_id">Valor</th>
            <th v-else colspan="2"></th>
          </tr>
          <template v-if="enquete && enquete.opcoes">
            <tr  v-for="i in enquete.opcoes.length">
              <td style="height: 40px;">
                <input type="text" name="pollOptionName" v-model="enquete.opcoes[i-1].texto" disabled/>
              </td>
              <td v-if="enquete.campanha_id">
                <input type="text" name="pollOptionVaue" v-model="enquete.opcoes[i-1].pontuacao" class="ml-1" disabled/>
              </td>
              <td v-if="enquete.dh_resultado">
                <span class="fa fa-check ml-2" v-if="resultados.includes(enquete.opcoes[i-1]['id'])" style="color:#00D26A;"></span>
                <span class="fa fa-times ml-2" v-else style="color:#FF6723;"></span>
              </td>
              <br/> 
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="3">...</td>
            </tr>
          </template>
        </table>
      </div>
      <div class="col-2 d-flex flex-column options-panel">
        <div>
          Data/hora Término:
          <date-picker v-model="enquete.dh_limite" type="datetime" value-type="YYYY-MM-DD HH:mm:ss" disabled></date-picker>
        </div>
        <div class="mt-3">
          Máx. de Respostas Permitidas: &nbsp; 
          <button type="button" class="btn btn-xs btn-info" @click="infoMaxResp()">
            <span class="fa fa-info"></span>
          </button>
        </div>      
        <input type="number" min="1" :max="opcoes.length" v-model="enquete.max_options" style="width: 6em;" disabled/>
          
        <div class="mt-3">
            Valor de participação: &nbsp;
            <button type="button" class="btn btn-xs btn-info" @click="infoValorParticipacao()">
              <span class="fa fa-info"></span>
            </button>
        </div>
        <input type="number" min="1" v-model="enquete.valor_participacao" style="width: 6em;" disabled/>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-12">
        <h4>Envios</h4>
        <div class="table-full-width table-responsive" style="overflow-y: hidden; overflow-x: hidden;">
          <table id="tbl-guru" class="display compact">
            <thead>
              <tr>
                <th class="sm">
                  <small style="font-weight: bold;">Nome Grupo</small>
                </th> 
                <th class="sm">
                  <small style="font-weight: bold;">Instância</small>
                </th> 
                <th class="sm">
                  <small style="font-weight: bold;">Status</small>
                </th>
                <th class="sm">
                  <small style="font-weight: bold;">Zaap Id</small>
                </th>
                <th class="sm">
                  <small style="font-weight: bold;">Message Id</small>
                </th>
                <th class="sm">
                  <small style="font-weight: bold;">Zapi Id</small>
                </th>
                <th class="sm">
                  <small style="font-weight: bold;">Operação</small>
                </th>
                <th class="sm">
                  <small style="font-weight: bold;">Envio</small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in enquete.envios" :key="index">
                  <td>
                      {{ grupos[item['grupo_id']]['nome'] }}
                  </td>
                  <td>
                      {{ instancias[grupos[item['grupo_id']]['instancia_id']]['nome'] }}
                  </td>
                  <td>
                      {{ item['status'] }}
                  </td>
                  <td>
                      {{ item['zapi_zaap_id'] }}
                  </td>
                  <td>
                      {{ item['zapi_message_id'] }}
                  </td>
                  <td>
                      {{ item['zapi_id'] }}
                  </td>
                  <td>
                      {{ item['operacao'] }}
                  </td>
                  <td>
                      {{ item['dh_envio'] }}
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <br/>
    <div class="row mt-3" v-if="enquete.dh_resultado">
      <div class="col-12">
        <h4>Ranking:</h4>
        <table class="table">
          <tr>
            <th>Posição</th>
            <th>Telefone</th>
            <th>Nome</th>
            <th>Pontuação</th>
          </tr>
          <tr v-for="r,i in ranking">
              <td>{{ i+1 }}</td>
              <td>{{ r['participant_phone'] }}</td>
              <td>{{ r['participant_name'] }}</td>
              <td>{{ r['pontuacao'] }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center button-bar">
        <base-button slot="footer" type="secondary" fill @click="back">Voltar</base-button>
        <base-button slot="footer" type="primary" fill @click="showModalResultado">Definir Resultado</base-button>
        <!-- <base-button slot="footer" type="primary" fill @click="salvar" :disabled="isEnviarDisabled()">Enviar</base-button> -->
      </div>
    </div>
  </div>
</template>
<script>
  import BootstrapToggle from 'vue-bootstrap-toggle'
  import config from '@/config';
  import { useRoute } from 'vue-router'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { VueEditor } from 'vue2-quill-editor'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  export default {
    components: { BootstrapToggle, Loading, VueEditor, DatePicker },
    data() {
      return {
        model: {},
        id: this.$route.params.id,
        enquete: {},
        tipo: 1,
        tiposGrupos: [],
        selectedTipoGrupo: null,
        grupos: {},
        gruposSelecionados: [],
        campanha: {},
        isLoading: true,
        isSendingMsg: false,
        marcarUsuarios: false,
        maxRespostas: 1,
        valorParticipacao: 0,
        resultados: [],
        ranking: [],
        isModalResultadoVisible: false,
        customToolbar: ['bold','italic'],
        opcoes: [{'nome':'', 'valor':0}, {'nome':'', 'valor':0}, {'nome':'', 'valor':0}, {'nome':'', 'valor':0}]
      }
    },
    methods: {
      getUsuario() {
        try {
          let user = JSON.parse(localStorage.ADMIN_AUTH_USER)
          return user
        } catch (error) {
          console.error(error)
          localStorage.ADMIN_AUTH_USER = null
        }
      },
      getEnquete(id){
        this.$aws
        .get('whatsapp/enquete/'+id)
        .then(response => {
          if (response.data) {
            let retorno = JSON.parse(JSON.stringify(response.data))
            this.enquete = retorno['enquete']
            if (this.enquete['resultados']) {
              this.resultados = this.enquete['resultados']
            }
            this.campanha = this.enquete['campanha']
            this.grupos = retorno['grupos']
            this.instancias = retorno['instancias']
            if (this.enquete.dh_resultado) {
              this.$aws
              .get('whatsapp/enquete/resultado/'+id)
              .then(response => {
                if (response.data) {
                  this.ranking = response.data 
                }
              })
              .catch(function (error) {
                    console.error(error)
              }); 
            }
          } else {
            this.model = {}
          }
          $(document).ready( function () {
            $('#tbl-guru').DataTable({
              ordering: false,
              lengthMenu: [50, 100, 300, 500],
              paging: false,
              searching: false,
              "language": {
                "emptyTable": "Nenhum registro disponível na tabela",
                "info":           "Total de _TOTAL_ envios",
                "infoEmpty":      "Nenhum Envio",
                "infoFiltered":   "(filtrado de um total de _MAX_ registros)",
                "lengthMenu":     "Mostrar _MENU_ registros",
                "loadingRecords": "Carregando...",
                "search":         "Busca:",
                "zeroRecords":    "Nenhum registro encontrado.",
                "paginate": {
                    "first":      "Primeiro",
                    "last":       "Último",
                    "next":       "Próximo",
                    "previous":   "Anterior"
                }
              },
            });
          });
          this.isLoading = false
        })
        .catch(function (error) {
              console.error(error)
        });   
      },
      getTipoGrupo(paramId) {
        let retorno = '?'
        for (let i=0; i<this.tiposGrupos.length; i++) {
          if (this.tiposGrupos[i]['id'] == paramId) {
            retorno = this.tiposGrupos[i]['nome']
          }
        }
        return retorno
      },
      infoMaxResp(){
        this.$swal("Máx. de Respostas Permitidas", "Define a quantidade máxima de respostas que o usuário poderá escolher na enquete.", "info");
      },
      infoValorParticipacao(){
        this.$swal("Valor de participação", "Define um valor que será concedido ao usuário que participar votando, caso o resultado apurado não esteja contemplado em nenhuma das alternativas da enquete.", "info");
      },
      back() {
        this.$router.push('/enquete')
      },
      showModalResultado(){
        this.isModalResultadoVisible = true
      },
      salvarResultado() {
        var vm = this
        
        vm.isLoading = true
        let post_body =  {'enquete_id': this.enquete.id, 'resultados_id': this.resultados}
        this.$aws
          .post('whatsapp/enquete/resultado', post_body)
          .then(response => {
            // console.log('# response:')
            // console.log(response)
            if (response.status == 200) {
              this.showModalResultado = false
              this.isLoading = false
              // this.getEnquete(this.id)
              this.$swal("Ok!", "Resultado Confirmado.", "success").then(function() {
                window.location.reload();
              })
            } else {
              vm.isLoading = false
              this.$swal("Ops!", "Ocorreu algum erro inesperado. (erro"+ response.status +") <br/> " + error.response.data, "error");
            }
          })
          .catch((error) => {
            console.error(error)  
            vm.isLoading = false
            vm.$swal("Ops!", "Ocorreu algum erro inesperado no envio da enquete: \n" + error, "error");
          });   
      },
      // definirResultado() {
      //   this.model.resultado
      // },
      getNome(nome) {
        const date = new Date()
        return date.getFullYear().toString() + ('0' + (date.getMonth()+1)).slice(-2) + ('0' + date.getDate()).slice(-2) + '_'
                + ('0' + (date.getHours()+1)).slice(-2) + ('0' + (date.getMinutes()+1)).slice(-2) + '_'
                + nome
      },
      decode(encodedString) {
          var textArea = document.createElement('textarea');
          textArea.innerHTML = encodedString;
          return textArea.value;
      },
      isEnviarDisabled() {
        if (this.tipo == 1) {
          return !this.selectedTipoGrupo 
        } else {
          return this.gruposSelecionados.length == 0
        }
      },
    },
    mounted() {
      let user = this.getUsuario()
      if (user && this.id) {
        if (this.id) {
          this.getEnquete(this.id)
        }
      }
    },
  };
</script>
<style lang="scss">
  h3 {
    margin: 0px;
  }
  .dataTables_wrapper {
    margin-top: 20px;
    tbody td:last-child {
      text-align: center;
    }
  }
  .button-bar {
    border-top: 2px solid #FC7D59;
    margin-top: 2em;
    padding-top: 2em;
  }
  .tipogrupo {
    background-color: #1E1E2C !important; 
  }
  .load-perc {
    color: #FC7D59;
    font-size: 1em;
    // position: fixed;
    // left: 49.3%;
    margin-top: 1em;
    text-align: center;
  }
  .grupo-nome {
    font-size: .7em;
  }
  .poll-option {
    margin-bottom: 1em;
    // min-height: 120px;
    border: 1px solid red;
    // vertical-align: middle;
    // display: table-cell;
  }
  .options-panel {
    background-color: #e8e9ef;
    padding-top: 2em;
    padding-bottom: 2em;
  }
  .check-hint {
    font-size: .8em;
  }
  .encerrada {
    background-color: #FF6723;
    color: white;
    padding-top: 1em;
    padding-bottom: 1em;
    text-align: center;
  }
  #quill-container {
    height: 10em;
  }
   /* HTML: <div class="loader"></div> */
  .loader {
  width: 50px;
  aspect-ratio: 1;
  --g: #0000 calc(100% - 5px),#FC7D59 calc(100% - 3px) 93%,#0000;
  background: 
    radial-gradient(farthest-side at bottom,var(--g)) top                  /100% 50%,
    radial-gradient(farthest-side at top   ,var(--g)) bottom 18% right  0  /80%  40%,
    radial-gradient(farthest-side at bottom,var(--g)) left   50% top    30%/60%  30%,
    radial-gradient(farthest-side at top   ,var(--g)) right  34% bottom 37%/40%  20%,
    radial-gradient(farthest-side at bottom,var(--g)) left   52% top    43%/24%  12%;
  background-repeat: no-repeat;
  animation: l20 1s infinite linear;
}
@keyframes l20 {
  100% {transform: rotate(1turn)}
}
.btn-group-xs > .btn, .btn-xs {
  padding: .25rem .4rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}
</style>
