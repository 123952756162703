<template>
  <div>
    <!-- <div class="row" style="background-color:red; color: white; padding: 4px;">
      <div class="col-md-12">
        {{ tipo }}
        <br/>
        {{ selectedTipoGrupo.id }}
      </div>
    </div> -->
    <div class="vld-parent">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
                 background-color="#000000">
                 <div style="padding: 5em; background-color: white; border-radius: 5%;">
                  <div class="d-flex justify-content-center">
                      <div class="loader"></div>
                  </div>
                  <br/>
                  <div>
                      <p v-if="isSendingFile">Upload do Arquivo: <span class="load-perc"> {{upload_perc}}%</span></p>
                      <p v-if="isAplicandoMarca">Aguarde:  
                          <span class="load-perc">Aplicando Logomarca... </span><br/><i>(Esta estapa pode demorar alguns minutos)</i> <br/>
                      </p>
                      <p v-if="isSendingMsg">Aguarde: 
                        <span class="load-perc"> Enviando Mensagem...</span> 
                      </p>
                      
                  </div>
                  </div>
        </loading>
    </div>
    <div class="row">
      <div class="col-md-8 mb-1 mt-3">
        <base-button class="btn btn-sm" :class="{'btn-primary': tipo==1}" fill @click="tipo=1">Por Tipo</base-button>
        <base-button class="btn btn-sm" :class="{'btn-primary': tipo==2}" fill @click="tipo=2">Avulso</base-button>
      </div>
    </div>
    <div class="row" v-if="tipo==1" style="margin-top:-10px;">
      <div class="col-md-8">
        <div style="border-bottom:2px solid #F9B9A9; border-top:2px solid #F9B9A9;" class="pb-2">
          <h4>Tipo:</h4>
          <select v-model="selectedTipoGrupo" class="form-control select-input">
            <option v-for="i in tiposGrupos" :value="i">{{ i.nome }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" v-if="tipo==2" style="margin-top:-10px;">
      <div class="col-md-8" >
        <div style="border-bottom:2px solid #F9B9A9; border-top:2px solid #F9B9A9;">
          <p>Selecione os grupos destinatários:</p>
          <div class="d-flex flex-wrap">
            <template v-for="grp in Object.values(grupos)">
              <div class="col-md-4">
                <input type="checkbox" :id="'grupos_'+grp.id" name="grupos"  v-model="gruposSelecionados" :value="grp.id"/> &nbsp;
                <label :for="'grupos_'+grp.id" class="grupo-nome">{{ grp.nome }}</label>
              </div>
            </template>
          </div>
          <br />
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-4">
        <h4>Mídia</h4>
        <input class="form-control" type="file" id="formFile"  @change="selectMidia" accept="image/*,video/*">
      </div>
      <div class="col-md-1 d-flex align-items-end">
        <base-button slot="footer" type="secondary" fill @click="limpar" style="margin-bottom:0px;">Limpar</base-button>
      </div>
      <div class="col-md-3">
        <h4>Logomarca</h4>
        <select v-model="selectedLogo" class="form-control select-input" :disabled="imageSrc==null || imageSrc==''">
          <option :value="null">Nenhuma</option>
          <option value="gurubets">Gurubets</option>
          <option value="gurubets_video">Gurubets - Vídeos</option>
          <option value="gurunews">Gurunews</option>
          <option value="gurunews_gurubets">Gurunews + Gurubets</option>
        </select>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-8">
        <h4>Texto</h4>
        <vue-editor v-model="mensagem" :editorToolbar="customToolbar"></vue-editor>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-md-3">
        <h4>Rodapé</h4>
        <select v-model="rodape" class="form-control select-input">
          <option :value="null">Nenhuma</option>
          <option :value="r" v-for="r in rodapes"> {{ r.nome }}</option>
        </select>
      </div>
      <div class="col-md-5 d-flex justify-content-end align-items-center">
        Marcar todos os usuários do grupo: &nbsp;
        <bootstrap-toggle v-model="marcarUsuarios" :options="{ on: 'Sim', off: 'Não' }" label="Marcar todos os usuários do grupo"/> 
      </div>
    </div>
    <br />
    <div class="row" v-if="rodape">
      <div class="col-md-8">
        <textarea disabled class="w-100" style="resize: none;" rows="4">{{ rodape.texto }}</textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-8 text-center button-bar">
        <base-button slot="footer" type="secondary" fill @click="back">Cancelar</base-button>
        <base-button slot="footer" type="primary" fill @click="salvar" :disabled="isEnviarDisabled()">Enviar</base-button>
      </div>
    </div>
  </div>
</template>
<script>
  import BootstrapToggle from 'vue-bootstrap-toggle'
  import config from '@/config';
  import { useRoute } from 'vue-router'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { VueEditor } from 'vue2-quill-editor'
  import util from '../common/util';

  export default {
    components: { BootstrapToggle, Loading, VueEditor },
    data() {
      return {
        columns: [
          { label: 'Id', field: 'id'},
          { label: 'Título', field: 'titulo'},
          { label: 'Texto', field: 'texto'},
          { label: 'Imagem', field: 'imagem'},
          { label: 'Publicação', field: 'datahora_publicacao'},
        ],
        userdata: {},
        model: {},
        publicado: false,
        tipo: 1,
        id: this.$route.params.id,
        tiposGrupos: [],
        grupos: {},
        gruposSelecionados: [],
        rodapes: [],
        rodape: null,
        mensagem: '',
        midia: null,
        imageSrc: null,
        selectedTipoGrupo: null,
        selectedLogo: null,
        searchOptions: {
              enabled: true,
              placeholder: 'Buscar...',
        },
        isLoading: false,
        isSendingFile: false,
        isSendingMsg: false,
        isAplicandoMarca: false,
        marcarUsuarios: false,
        upload_perc: 0,
        BUCKET_NAME:  'https://guru-admin-files.s3.amazonaws.com/',
        customToolbar: ['bold','italic']
      }
    },
    watch: {
      publicado: function(newValue, oldValue){
        if (newValue) {
          this.model.status = 1
        } else {
          this.model.status = 0
        }
      }
    },
    methods: {
      getUsuario() {
        try {
          let user = JSON.parse(localStorage.ADMIN_AUTH_USER)
          return user
        } catch (error) {
          console.error(error)
          localStorage.ADMIN_AUTH_USER = null
        }
      },
      back() {
        this.$router.push('/mensagem')
      },
      salvar() {
        this.isLoading = true
        var vm = this
        this.model.mensagem = util.tratarTexto(this.mensagem)
        if (this.rodape) {
          this.model.mensagem += '\n'
          this.model.mensagem += util.tratarTexto(this.rodape.texto)
        }

        this.model.marcacao = this.marcarUsuarios
        
        if (this.tipo == 1) {
          this.model.tipo = this.selectedTipoGrupo['id']
        } else {
          this.model.grupos = this.gruposSelecionados
        }

        let nome = null
        let tipoMidia = null

        if (this.imageSrc) {
          this.isSendingFile = true
          nome = this.imageSrc.name 
          tipoMidia = this.imageSrc.type
          
          let params = {
            name: nome, 
            type: this.imageSrc.type,
          }
          let putLogo = false
          if (this.selectedLogo) {
            putLogo = this.selectedLogo
          } 
          params['put_logo'] = putLogo

          this.$aws.post('whatsapp/mensagem/upload-url', params).then(response => {
            let presigned_url = response.data
            this.$http.put(presigned_url, this.imageSrc, {
              headers: { "Content-Type": this.imageSrc.type, "x-amz-meta-logo": putLogo},
              onUploadProgress: (e) => {
                vm.upload_perc = Math.round((e.loaded * 100) / e.total);
              },
            }).then(response => {
              if (putLogo) {
                vm.model.midia = nome
                vm.model.tipo_midia = tipoMidia
                // vm.isSendingMsg = true
                vm.isAplicandoMarca = true
                vm.consultaMidia(vm.imageSrc.name, 0)
              } else {
                vm.model.midia = nome
                vm.model.tipo_midia = tipoMidia
                vm.isSendingMsg = true
                vm.salvarMensagem(this.model)
              }
            })
            .catch(function (error) {
              vm.isLoading = false
              vm.isSendingMsg = false
              vm.isSendingFile = false
              console.error(error)  
              vm.$swal("Ops!", "Ocorreu algum erro inesperado ao enviar o arquivo: \n"+ error, "error");
            }); 
          })
          .catch(function (error) {
            vm.isLoading = false
            vm.isSendingMsg = false
            vm.isSendingFile = false
            console.error(error)  
            vm.$swal("Ops!", "Ocorreu algum erro inesperado na requisição à AWS: \n"+ error, "error");
          }); 
        } else {
          vm.isSendingMsg = true
          vm.salvarMensagem(this.model)
        }
      }, 
      
      // consultaMidia(nomeMidia, loopcount) {
      //   var vm = this
      //   this.$aws
      //     .post('whatsapp/mensagem/marcadagua', {'media': nomeMidia})
      //     .then(response => {
      //       if (response.status == 200 || response.data.status == 200) {
      //         let processamentoFeito = response.data && response.data.status == 1 
      //         if (processamentoFeito) {
      //           vm.isAplicandoMarca = false
      //           vm.isSendingMsg = true
      //           vm.salvarMensagem(this.model)
      //         } else {
      //           setTimeout(function () {
      //               this.consultaMidia(nomeMidia, loopcount+1)
      //           }.bind(this), 5000)
      //         }
      //       }
      //     })
      //     .catch(function (error) {
      //           vm.isLoading = false
      //           vm.isSendingMsg = false
      //           vm.isSendingFile = false
      //           vm.isAplicandoMarca = false
      //           console.error(error)  
      //           vm.$swal("Ops!", "Ocorreu algum erro ao colocar a logo no video: \n"+ error, "error");
      //           return false
      //     });
      // } ,
      
      salvarMensagem(model) {
        var vm = this
        //  this.$aws
        //   .post('whatsapp/mensagem/enviar', model)
        this.$backend
          .post('mensagem_envio', model)
          .then(response => {
            if (response.status == 200) {
              this.$swal("Ok!", "Mensagem Enviada.", "success");
              this.$router.push('/mensagem')
            } else {
              vm.isLoading = false
              this.$swal("Ops!", "Ocorreu algum erro inesperado. (erro"+ response.status +")", "error");
            }
          })
          .catch(function (error) {
            console.error(error)  
            vm.isLoading = false
            vm.isAplicandoMarca = false
            vm.$swal("Ops!", "Ocorreu algum erro inesperado no envio da mensagem: \n"+ error, "error");
          });   
      },
      async consultaMidia(nomeMidia, loopcount) {
        var vm = this
        //Consulta para ver se ja foi processado
        let is_processing = true
        let loop_count = 0
        // let max_loop = 120 //10 min
        // (async () => {
        while (is_processing) {
            try {
              loop_count += 1
              let resp = await this.$aws.post('whatsapp/mensagem/marcadagua', {'media': nomeMidia});
              is_processing = resp.data == null || resp.data.status != 1
              console.log('processando video [' + loop_count +']: ' + is_processing)
            } catch (error) {
              vm.isLoading = false
              vm.isSendingMsg = false
              vm.isSendingFile = false
              vm.isAplicandoMarca = false
              is_processing = false
              console.error(error)  
              vm.$swal("Ops!", "Ocorreu algum erro ao colocar a logo no video: \n"+ error, "error");
            }
            console.log('Aguardando 5s...');
            if (is_processing) {
              await util.timeout(5);
            }
        }
        vm.isAplicandoMarca = false
        vm.isSendingMsg = true 
        vm.salvarMensagem(this.model)
      },
      getNome(nome) {
        const date = new Date()
        return date.getFullYear().toString() + ('0' + (date.getMonth()+1)).slice(-2) + ('0' + date.getDate()).slice(-2) + '_'
                + ('0' + (date.getHours()+1)).slice(-2) + ('0' + (date.getMinutes()+1)).slice(-2) + '_'
                + nome
      },
      limpar() {
        this.imageSrc = null;
        document.querySelector('input[type=file]').value = '';
      },
      selectMidia() {    
        this.imageSrc = document.querySelector('input[type=file]').files[0]
      },
      isEnviarDisabled() {
        if (this.tipo == 1) {
          return !this.selectedTipoGrupo 
        } else {
          return this.gruposSelecionados.length == 0
        }
      }
    },
    mounted() {
      this.$aws
      .get('whatsapp/tipogrupo')
      .then(response => {
        if (response.data) {
          this.tiposGrupos = response.data 
        }
      })
      .catch(function (error) {
            console.error(error)
      }); 

      this.$aws
      .get('whatsapp/grupo')
      .then(response => {
        if (response.data) {
          this.grupos = response.data 
        }
      })
      .catch(function (error) {
            console.error(error)
      }); 

      this.$aws
      .get('whatsapp/rodape/')
      .then(response => {
        let rodapes = JSON.parse(JSON.stringify(response.data))
        this.rodapes = []
        rodapes.forEach((el)=> {
          if (el.ativo == 1) {
            this.rodapes.push(el)
          }
        })
      })
      .catch(function (error) {
              console.error(error)
        }); 
      },
  };
</script>
<style lang="scss">
  h3 {
    margin: 0px;
  }
  .dataTables_wrapper {
    margin-top: 20px;
    tbody td:last-child {
      text-align: center;
    }
  }
  .button-bar {
    border-top: 2px solid #FC7D59;
    margin-top: 2em;
    padding-top: 2em;
  }
  .tipogrupo {
    background-color: #1E1E2C !important; 
  }
  .load-perc {
    color: #FC7D59;
    font-size: 1em;
    // position: fixed;
    // left: 49.3%;
    margin-top: 1em;
    text-align: center;
  }
  .grupo-nome {
    font-size: .7em;
  }
   /* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  --g: #0000 calc(100% - 5px),#FC7D59 calc(100% - 3px) 93%,#0000;
  background: 
    radial-gradient(farthest-side at bottom,var(--g)) top                  /100% 50%,
    radial-gradient(farthest-side at top   ,var(--g)) bottom 18% right  0  /80%  40%,
    radial-gradient(farthest-side at bottom,var(--g)) left   50% top    30%/60%  30%,
    radial-gradient(farthest-side at top   ,var(--g)) right  34% bottom 37%/40%  20%,
    radial-gradient(farthest-side at bottom,var(--g)) left   52% top    43%/24%  12%;
  background-repeat: no-repeat;
  animation: l20 1s infinite linear;
}
@keyframes l20 {
  100% {transform: rotate(1turn)}
}
</style>
