<template>
  <div>
    <div class="vld-parent">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
                 background-color="#000000">
                <div style="padding: 5em; background-color: white; border-radius: 5%;">
                  <div class="d-flex justify-content-center">
                      <div class="loader"></div>
                  </div>
                </div>
        </loading>
    </div>
    <br />
    <div class="row">
      <div class="col-8">
        <h4>Nome</h4>
        <input type="text" name="campanha-name" v-model="model.nome" class="w-100"/>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-8">
        <h4>Texto</h4>
        <vue-editor v-model="model.texto" :editorToolbar="customToolbar"></vue-editor>
      </div>
    </div>
    <br/>
    <div class="row">
        <div class="col-md-8 d-flex justify-content-end align-items-center">
          Ativo: &nbsp;
          <bootstrap-toggle v-model="model.ativo" :options="{ on: 'Sim', off: 'Não' }" label="Marcar todos os usuários do grupo"/> 
      </div>
    </div>
    <div class="row">
      <div class="col-8 text-center button-bar">
        <base-button slot="footer" type="secondary" fill @click="back">Cancelar</base-button>
        <base-button slot="footer" type="primary" fill @click="salvar" :disabled="isEnviarDisabled()">Enviar</base-button>
      </div>
    </div>
  </div>
</template>
<script>
  import config from '@/config';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import { VueEditor } from 'vue2-quill-editor'
  import BootstrapToggle from 'vue-bootstrap-toggle'
  import util from '../common/util';
  
  export default {
    components: { Loading, DatePicker, VueEditor, BootstrapToggle},
    data() {
      return {
        id: this.$route.params.id,
        model: {
          id: null,
          nome: "",
          texto: "",
          ativo: true
        },
        isLoading: false,
        customToolbar: ['bold','italic']
      }
    },
    methods: {
      back() {
        this.$router.push('/rodape')
      },
      salvar() {
        var vm = this
        vm.isLoading = true

        this.model.texto = util.tratarTexto(this.model.texto)
        this.model.ativo = this.model.ativo ? 1 : 0
        
        this.$aws
          .post('whatsapp/rodape', this.model)
          .then(response => {
            if (response.status == 200) {
              this.$swal("Ok!", "Rodapé Salvo.", "success");
              this.$router.push('/rodape')
            } else {
              vm.isLoading = false
              this.$swal("Ops!", "Ocorreu algum erro inesperado. (erro"+ response.status +") <br/> " + error.response.data, "error");
            }
          })
          .catch((error) => {
            console.error(error)  
            vm.isLoading = false
            vm.$swal("Ops!", "Ocorreu algum erro ao salvar o rodapé: \n" + error, "error");
          });   
      },
      isEnviarDisabled() {
        if (this.model.nome != '') {
          return false
        } else {
          return true
        }
      },
    },
    mounted() {
      if (this.id) {
        this.$aws
        .get('whatsapp/rodape/'+this.id)
        .then(response => {
          this.model = JSON.parse(JSON.stringify(response.data))
        })
        .catch(function (error) {
              console.error(error)
        }); 
      }
    },
  };
</script>
<style lang="scss">
  h3 {
    margin: 0px;
  }
  .dataTables_wrapper {
    margin-top: 20px;
    tbody td:last-child {
      text-align: center;
    }
  }
  .button-bar {
    border-top: 2px solid #FC7D59;
    margin-top: 2em;
    padding-top: 2em;
  }
  .tipogrupo {
    background-color: #1E1E2C !important; 
  }
  .load-perc {
    color: #FC7D59;
    font-size: 1em;
    // position: fixed;
    // left: 49.3%;
    margin-top: 1em;
    text-align: center;
  }
  .grupo-nome {
    font-size: .7em;
  }
  .poll-option {
    margin-bottom: 1em;
    // min-height: 120px;
    border: 1px solid red;
    // vertical-align: middle;
    // display: table-cell;
  }
  .options-panel {
    background-color: #e8e9ef;
    padding-top: 2em;
  }
  #quill-container {
    height: 10em;
  }
   /* HTML: <div class="loader"></div> */
  .loader {
  width: 50px;
  aspect-ratio: 1;
  --g: #0000 calc(100% - 5px),#FC7D59 calc(100% - 3px) 93%,#0000;
  background: 
    radial-gradient(farthest-side at bottom,var(--g)) top                  /100% 50%,
    radial-gradient(farthest-side at top   ,var(--g)) bottom 18% right  0  /80%  40%,
    radial-gradient(farthest-side at bottom,var(--g)) left   50% top    30%/60%  30%,
    radial-gradient(farthest-side at top   ,var(--g)) right  34% bottom 37%/40%  20%,
    radial-gradient(farthest-side at bottom,var(--g)) left   52% top    43%/24%  12%;
  background-repeat: no-repeat;
  animation: l20 1s infinite linear;
}
@keyframes l20 {
  100% {transform: rotate(1turn)}
}
.btn-group-xs > .btn, .btn-xs {
  padding: .25rem .4rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}
</style>
