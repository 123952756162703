export default {
    tratarTexto: function(textoOriginal) {
        let retorno = this.decode(textoOriginal)
        if (retorno.endsWith('</p>')) { //evita que o <p> wrap cause uma newline em todos os textos
          retorno = retorno.substring(0, retorno.length - 4);
        }
        retorno = retorno.replaceAll('<p>','')
        retorno = retorno.replaceAll('</p>','\n')
        retorno = retorno.replaceAll('<strong>','*')
        retorno = retorno.replaceAll('</strong>','*')
        retorno = retorno.replaceAll('<em>','_')
        retorno = retorno.replaceAll('</em>','_')
        retorno = retorno.replaceAll('<br />','\n')
        retorno = retorno.replaceAll(/<.*?>/gm, '')
        return retorno
    },
    decode: function(encodedString) {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = encodedString;
        return textArea.value;
    },
    timeout:  function(sec) {
        return new Promise(resolve => setTimeout(resolve, sec*1000));
    },
  
}